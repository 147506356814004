import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import {Grid} from "@material-ui/core";
import {Button, FormControl, FormControlLabel, FormGroup, TextField} from "@mui/material";
import { useSpeechSynthesis } from 'react-speech-kit';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {getQueryVariable} from "./functions";
import Checkbox from '@mui/material/Checkbox';
const text = "Sofie fell out of the portal and looked around." +
    "Sofie was sitting in a big, rumbly car!";
    //" Sofie gasped and put a seatbelt on. Then the Power Pen began to sing!<br><br>\"In this race of magic and wheel, <br>With rumbling motor and enchanted steel, <br>Help your friend to win the race, <br>With your help, Sofie, you’ll both come first place!\" <br>Sofie looked at the driver’s seat of the car, there was a boy with goat’s legs and little horns on top of his head! <br>“Hello Sofie, I was wondering when you’d show up,” he said, “My name is Sam!”\n";

function withMyHook(Component) {
    const onEnd = () => {}
    return function WrappedComponent(props) {

        const speak = useSpeechSynthesis({onEnd});
        // console.log(speak)
        return <Component {...props} speak={speak} />;
    }
}
class Body extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            play: false,
            playAll: true,
            child_name: '',
            book_text: [],
            loading: true,
            page_no: 0,
        }
        this.page_no = 0;
    }
    componentDidMount() {

        axios.get('https://personalshopper.superpersonalisedbooks.co.uk/audio/api/audio/', {
                params:
                    {
                        'book': getQueryVariable('book')
                    }
            }
        ).then(res=> {
            this.setState({
                book_text: res.data.data,
                loading: false,
            })
        })
    }
    startPlaying = () => {
        if(this.state.play===false)
            this.setState({play: true});

        this.props.speak.speak({
            text: this.state.book_text[this.state.page_no].text
                .replaceAll('Sofie', this.state.child_name)
                .replaceAll('<br/>', ''),
            pitch: 2.0,
            rate: 0.6,

        });

    }

    render() {
        console.log(this);

        return <Grid container spacing={2} >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.loading}
/>
            <Grid item xs={6} style={{backgroundImage: "https://superpersonalisedbooks.co.uk/wp-content/uploads/2021/08/wacky-race.png"}}>


            </Grid>
            <Grid item xs={4}>
                <Grid alignContent={'flex-start'} alignItems={'flex-start'} container spacing={2} style={{margin: '1em'}}>
                    <FormGroup>
                        <TextField
                            placeholder='INSERT CHILD NAME'
                            name='child_name'
                            value={this.state.child_name}
                            onChange={
                                (e) => this.setState({[e.target.name]: e.target.value})
                            }
                        />
                        <FormControlLabel control={<Checkbox
                            name='playAll'
                            value={this.state.playAll}
                            onChange={(e) => this.setState({[e.target.name]: !this.state[e.target.name]})}
                        />} label={'Play All Pages?'}/>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.page_no}
                                name='page_no'
                                label="Age"
                                onChange={
                                    (e) => this.setState({[e.target.name]: e.target.value})

                                }
                            >
                                {this.state.book_text.map((book, id) =>
                                    <MenuItem value={id}>{id}-{book.page_no}</MenuItem>

                                )}

                            </Select>
                        </FormControl>


                    </FormGroup>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid container>
                            {!this.props.speak.speaking ?

                                <Button
                                    onClick={this.startPlaying}
                                    variant={"outlined"}

                                >Play</Button>
                                :
                                <Button
                                    onClick={this.props.speak.cancel}
                                    variant={"outlined"}

                                >Stop Playing</Button>
                            }
                    </Grid>
                    { this.props.speak.speaking ?
                    <Grid item>
                        PAGE NO PLAYING: {this.page_no+1}
                    </Grid>
                        : ''}
                </Grid>
            </Grid>
        </Grid>
    }
}



function Example() {
    const [value, setValue] = useState('');
    const speak = useSpeechSynthesis();
    console.log(speak);
    return (
        <div>
      <textarea
          value={value}
          onChange={(event) => setValue(event.target.value)}
      />
            <button onClick={() => speak.speak({ text: text })}>Speak</button>
        </div>
    );
}
export default withMyHook(Body);

// export default Example;
