import {red, blue, lightBlue, yellow, deepOrange, deepPurple}  from '@material-ui/core/colors';
import {createTheme} from '@mui/material/styles';
import poppins from './assets/fonts/Poppins/Poppins-Black.ttf';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff7dc',
            contrastText: red.A400,

        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#e4f9f8',
        },
        type: 'light',
        text: {

            primary: deepPurple.A700,
            secondary: deepOrange.A400,
            disabled: red.A700
        }
    },
    typography: {
        fontFamily: poppins,

        subtitle1: {
            fontFamily: poppins,
            color: '#55c0df',
        },
        button: {
            backgroundColor: '#55c0df',

            fontStyle: "italic"
        },

    },
    headlines: {
        headline: {
            fontFamily: poppins,
            color: '#55c0df'
        },
        subHeadline: {
            fontFamily: poppins,
            color: '#fac5a8'
        }
    }

});

export default theme;
