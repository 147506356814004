
import React from 'react';
import './App.css';
import {Grid} from "@material-ui/core";
import {styled, ThemeProvider} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Header from "./Header";
import theme from './theme';
import {Button} from "@mui/material";
import Body from "./body";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function App() {
  return (
    <div className="App">
        <ThemeProvider theme={theme}>

        <Header/>
        <Body />
        </ThemeProvider>
    </div>
  );
}

export default App;
